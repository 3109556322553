import { ShardCard } from '@irishlifedigitalhub/ds.molecules.shard-card';
import React from 'react';
import { Text } from '@irishlifedigitalhub/ds.atoms.text';
import { IShardCard } from '../HeroWithPillarLinks';
import { getJumbotronCallToActionData } from '../../Jumbotron';
import { BasicButton } from 'common/components/atoms/BasicButton';
import { Box } from '@chakra-ui/react';

const ShardCardCarrousel = ({
  shardCard,
  stepper,
}: {
  shardCard: IShardCard;
  stepper: JSX.Element;
}) => {
  let callToAction;

  if (
    shardCard.templatesection___shardcard__cta &&
    shardCard.templatesection___shardcard__cta.length > 0
  ) {
    callToAction = getJumbotronCallToActionData(
      shardCard.templatesection___shardcard__cta[0]
    );
  }

  return (
    <ShardCard
      callback={() => {}}
      noCallback
      imageSrc={shardCard.templatesection___shardcard__image}
    >
      <Box
        display="flex"
        width="100%"
        height="100%"
        flexDirection="column"
        gap="36px"
      >
        <Box display="flex" flexDirection="column" gap="12px" overflow="hidden">
          {shardCard.templatesection___shardcard__title && (
            <Text color={'brand.800'} variant={'title-lg'}>
              {shardCard.templatesection___shardcard__title}
            </Text>
          )}

          {shardCard.templatesection___shardcard__text && (
            <Text
              variant={'body-md'}
              color={'Monochrome.800'}
              marginTop={'0px !important'}
            >
              {shardCard.templatesection___shardcard__text}
            </Text>
          )}
        </Box>

        {callToAction && (
          <BasicButton
            {...callToAction}
            size="medium"
            noSpacer
            label={callToAction?.label}
          />
        )}

        {stepper}
      </Box>
    </ShardCard>
  );
};

export { ShardCardCarrousel };
